import React from 'react';

const Logo = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 62.78 30.54" xmlns="http://www.w3.org/2000/svg" fill="*0E0E0E">
    <path class="cls-1" d="M2.12,0h8.46a2.12,2.12,0,0,1,1.5.62L29.92,18.47a2.12,2.12,0,0,1,.62,1.5v8.45a2.12,2.12,0,0,1-2.12,2.12H2.12A2.12,2.12,0,0,1,0,28.42V21.21a2.12,2.12,0,0,1,2.12-2.12H9.54a2.12,2.12,0,0,1,2.13,2.12v5.52H22.91L.62,4.44A2.12,2.12,0,0,1,0,2.94V2.12A2.12,2.12,0,0,1,2.12,0Z"/>
    <path class="cls-1" d="M43.91,14.44,33.09,3.82H43.48V9.33a2.12,2.12,0,0,0,2.12,2.12H53a2.12,2.12,0,0,0,2.12-2.12V2.12A2.12,2.12,0,0,0,53,0H26.94a2.12,2.12,0,0,0-2.12,2.12V9.73a2.12,2.12,0,0,0,.62,1.5l6.39,6.39a2.12,2.12,0,0,1,.62,1.5v9.3a2.12,2.12,0,0,0,2.12,2.12H60.66a2.12,2.12,0,0,0,2.12-2.12V21.21a2.12,2.12,0,0,0-2.12-2.12H53.24a2.12,2.12,0,0,0-2.12,2.12v5.52H44.54V16A2.13,2.13,0,0,0,43.91,14.44Z"/>
    </svg>

  )
}

export default Logo;
